import React, { useRef } from "react"

import Layout from "../components/Layout/layout"

export default function RefreshData(props) {
  const ref = useRef(null);
  const handleClick = () => {
    ref.current.setAttribute("disabled", "disabled");
    fetch('/__refresh', { method: 'post' }).then(() => {
      window.setTimeout(function () { ref.current.removeAttribute("disabled"); }, 6000);
    })
  }
  return (<Layout>
    <h1>Refresh Data</h1>
    <p>Hit the refresh button, content updates from CMS will be applied soon to the site.</p>

    <button className="btn" ref={ref} onClick={() => handleClick()}>
      Refresh
        </button>
  </Layout>)
}


